<template>
  <div class="bg-wscgreen" id="app">
    <AppNavbar
      :user="user"
      @logout="logout"
      @open-disclaimer-modal="openDisclaimerModal"
      @set-current-page="setCurrentPage"
      @update-username="updateUsername"
      @refresh-user="checkUserSession"
    />

    <ErrorNotification :errors="errors" @dismiss-error="removeError" />
    <!-- Side Panel -->
    <SidePanel
      :quota="quota"
      :user="user"
      @update-panel-width="updatePanelWidth"
    />
    <div class="main-layout" :style="{ marginLeft: panelWidth + 'px' }">
      <!-- Main Content -->
      <div class="main-content container mt-4">
        <div class="row" v-if="currentPage === 'home' && user">
          <div class="col-md-4 col-sm-12 mb-2">
            <TicketList
              :tickets="tickets"
              :quota="quota"
              :selectedTicket="selectedTicket"
              @select-ticket="selectTicket"
              @open-modal="openModal"
            />
          </div>

          <div class="col-md-8 col-sm-12">
            <TicketDetails
              v-if="selectedTicket"
              :selectedTicket="selectedTicket"
              @regenerate-ai-response="regenerateAiResponse"
              @delete-ticket="deleteTicket"
              @open-disclaimer-modal="openDisclaimerModal"
              :quota="quota"
            />
            <div v-else>
              <p>Select a ticket to view details.</p>
            </div>
          </div>
        </div>
        <div class="row" v-if="currentPage === 'home' && !user">
          <div class="d-flex justify-content-center align-items-center mt-60">
            <div class="text-center">
              <h1 class="sub-title">Welcome to</h1>
              <img src="@/assets/imgs/logo-black.svg" class="landing-logo" />
              <h1 class="challenge-title">GenAI Security Challenge</h1>

              <a href="/login" class="btn btn-dark btn-lg">Login with Google</a>

              <div class="alert alert-info mt-4 welcome-alert" role="alert">
                <h4>Why do I need a Google account?</h4>
                <p>
                  This challenge uses an LLM, which has significant costs. To
                  ensure fair usage and manage costs, we require participants to
                  log in using their Google account.
                </p>
                <p>
                  Your email will ONLY be used for enforcing quotas and
                  rate-limiting (absolutely no marketing, tracking or
                  recruitment). You can delete your account any time and no
                  information will be retained.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="currentPage === 'research'">
          <GenAIResearch />
        </div>
        <LeaderBoard v-if="currentPage === 'leaderboard'" />
      </div>
    </div>

    <footer>
      <a
        href="https://www.withsecure.com/en/about-us/legal/terms#terms"
        target="_blank"
        class="tx-wscgreen"
        >Terms of service</a
      >
      <a
        href="https://www.withsecure.com/en/about-us/legal/privacy#privacy"
        target="_blank"
        class="tx-wscgreen"
        >Privacy</a
      >
      <a
        href="https://www.withsecure.com/en/about-us/legal/privacy#cookies"
        target="_blank"
        class="tx-wscgreen"
        >Cookies</a
      >
      <span class="social-media-icons">
        <a
          href="https://www.linkedin.com/showcase/withsecure-consulting"
          target="_blank"
          class="social-media-link me-lg-2 ms-lg-0 me-2 tx-wscgreen"
        >
          <!-- <img src="/wp-content/uploads/icon-linkedin.svg" /> -->
          <!--?xml version="1.0" encoding="UTF-8"?-->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="social-media-icon"
            width="30"
            height="30"
          >
            <path
              fill="currentColor"
              stroke-opacity="0"
              stroke="null"
              d="m19.76459,6.83211l-9.5292,0c-1.87929,0 -3.40328,1.524 -3.40328,3.40328l0,9.5292c0,1.8793 1.524,3.4033 3.40328,3.4033l9.5292,0c1.87998,0 3.4033,-1.524 3.4033,-3.4033l0,-9.5292c0,-1.87929 -1.52332,-3.40328 -3.4033,-3.40328zm-7.48723,12.93248l-2.04197,0l0,-7.48723l2.04197,0l0,7.48723zm-1.02099,-8.35029c-0.65751,0 -1.19114,-0.53772 -1.19114,-1.20069s0.53363,-1.20067 1.19114,-1.20067s1.19115,0.53772 1.19115,1.20067s-0.53295,1.20069 -1.19115,1.20069zm9.18887,8.35029l-2.04197,0l0,-3.8144c0,-2.29245 -2.72262,-2.11888 -2.72262,0l0,3.8144l-2.04197,0l0,-7.48723l2.04197,0l0,1.20136c0.95019,-1.76018 4.76459,-1.89019 4.76459,1.6853l0,4.60056z"
            ></path>
          </svg>
        </a>
        <a
          href="https://x.com/withconsulting"
          target="_blank"
          class="social-media-link me-lg-2 ms-lg-0 me-2 tx-wscgreen"
        >
          <!-- <img src="/wp-content/uploads/icon-x.svg" /> -->
          <!--?xml version="1.0" encoding="UTF-8"?-->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="social-media-icon"
            width="30"
            height="30"
          >
            <path
              stroke="null"
              fill="currentColor"
              d="m16.53127,13.74094l5.98962,-6.96309l-1.41996,0l-5.2012,6.04595l-4.15399,-6.04595l-4.7909,0l6.28194,9.14199l-6.28194,7.30233l1.41996,0l5.49217,-6.38518l4.3873,6.38518l4.7909,0l-6.51391,-9.48122l0,0l0.00001,0zm-1.94425,2.25935l-0.63692,-0.91044l-5.06443,-7.24333l2.18024,0l4.08695,5.84615l0.63692,0.91044l5.31249,7.59866l-2.18024,0l-4.33501,-6.20148l0,0z"
            ></path>
          </svg>
        </a>
      </span>
      <a href="https://consulting.withsecure.com" target="_blank">
        <img class="footer-logo" src="@/assets/imgs/ws-consulting-logo.svg"
      /></a>
    </footer>

    <!-- Disclaimer Modal -->
    <div
      class="modal fade"
      :class="{ 'show d-block': isDisclaimerModalActive }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Disclaimer</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeDisclaimerModal"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              This application is part of a Capture The Flag (CTF) challenge and
              is purely for educational and entertainment purposes. The
              scenarios, advice, and interactions provided by the LLM in this
              application are entirely make-believe and should
              <strong>not</strong> be interpreted as real medical advice.
            </p>

            <ul>
              <li>
                <strong>Not for real medical use</strong>: The information
                generated by this system is fictional and should be disregarded.
              </li>
              <li>
                <strong>No responsibility</strong>: We take no responsibility
                for any actions taken based on the output of this application.
                Always consult a licensed healthcare professional for any real
                medical concerns.
              </li>
              <li>
                <strong>Not liable</strong>: The creators of this application
                and the organizers of the CTF are not liable for any
                consequences resulting from the use of this application.
              </li>
            </ul>

            <p>
              By using this application, you agree to these terms and
              acknowledge that it is a simulated environment not intended for
              real-world use.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeDisclaimerModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Consultation Creation Modal -->
    <div
      class="modal fade"
      :class="{ 'show d-block': isModalActive }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">New consultation</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitTicket">
              <p>
                Describe your symptoms, their severity and how long you've been
                having them for.
              </p>
              <div class="mb-3">
                <label class="form-label">Describe your issue</label>
                <textarea
                  rows="8"
                  class="form-control"
                  v-model="description"
                  placeholder="I have had a bad cough for 3 days and yesterday I started feeling feverish, not too high temperature."
                  required
                ></textarea>
              </div>
            </form>
            <div class="text-danger m-3">
              *Disclaimer: This application was built for a CTF and is not
              intended for real medical advice. Please refer to the full
              disclaimer.
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="submitTicket"
              :disabled="quota.remaining_consultations === 0"
            >
              Submit Ticket
            </button>
          </div>
        </div>
      </div>
    </div>
    <CookieConsent />
  </div>
</template>

<script>
import AppNavbar from "./components/AppNavbar.vue";
import ErrorNotification from "./components/ErrorNotification.vue";
import SidePanel from "./components/SidePanel.vue";
import TicketList from "./components/TicketList.vue";
import TicketDetails from "./components/TicketDetails.vue";
import GenAIResearch from "./components/GenAIResearch.vue";
import LeaderBoard from "./components/LeaderBoard.vue";
import CookieConsent from "./components/CookieConsent.vue";

export default {
  components: {
    AppNavbar,
    SidePanel,
    TicketList,
    TicketDetails,
    ErrorNotification,
    GenAIResearch,
    LeaderBoard,
    CookieConsent,
  },
  data() {
    return {
      user: null,
      currentPage: "home",
      tickets: [],
      selectedTicket: null,
      isModalActive: false,
      isDisclaimerModalActive: false,
      panelWidth: 50,
      title: "",
      description: "",
      quota: {
        max_consultations: 0,
        remaining_consultations: 0,
        max_ai_replies: 0,
        remaining_ai_replies: 0,
      },
      errors: [],
    };
  },
  methods: {
    async submitTicket() {
      if (
        this.quota.remaining_consultations === 0 ||
        this.quota.remaining_ai_replies === 0
      ) {
        this.addError("You have reached your quota.");
        this.closeModal();
        return;
      }

      try {
        const response = await fetch("/api/tickets", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: this.title,
            description: this.description,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Error creating ticket");
        }

        const ticket = await response.json();

        // Add the new ticket to the list and set it as the selected ticket
        this.tickets.push(ticket);
        this.selectTicket(ticket);

        this.title = "";
        this.description = "";
        this.closeModal();

        // Update quota after creating a ticket
        await this.fetchQuota();
      } catch (error) {
        this.addError(`Ticket creation failed: ${error.message}`);
        this.closeModal();
      }
    },

    async fetchTickets() {
      try {
        const response = await fetch("/api/tickets");
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Error fetching tickets");
        }

        const data = await response.json();

        // Iterate over fetched tickets and update only relevant ones
        data.tickets.forEach((fetchedTicket) => {
          const localTicket = this.tickets.find(
            (t) => t.ticket_id === fetchedTicket.ticket_id
          );

          // Update the ticket if AI response is ready
          if (localTicket) {
            if (fetchedTicket.messages.length !== localTicket.messages.length) {
              localTicket.messages = fetchedTicket.messages;
            }
          } else {
            // Add new tickets
            this.tickets.push(fetchedTicket);
          }
        });

        // Auto-select the first ticket if none is selected
        if (this.tickets.length > 0 && !this.selectedTicket) {
          this.selectTicket(this.tickets[0]);
        }

        setTimeout(() => {
          this.fetchTickets();
        }, 10000); // Poll for updates every 10 seconds
      } catch (error) {
        this.addError(`Fetching tickets failed: ${error.message}`);
      }
    },
    checkHashAndRedirect() {
      const hash = window.location.hash.replace("#", ""); // Remove the '#' from the hash
      // Check the value of the hash and set the correct page
      if (hash === "leaderboard") {
        this.setCurrentPage("leaderboard");
      } else if (hash === "research") {
        this.setCurrentPage("research");
      } else {
        this.setCurrentPage("home"); // Default to 'home' if no matching hash
      }
    },

    async regenerateAiResponse(ticketId, messageId) {
      if (this.quota.remaining_ai_replies === 0) {
        this.addError("You have reached the AI reply generation quota.");
        return;
      }

      const aiMessage = this.selectedTicket.messages.find(
        (msg) => msg.id === messageId && msg.is_ai_response
      );
      if (!aiMessage) return;

      aiMessage.loading = true; // Set loading state for this specific AI message

      try {
        const response = await fetch(`/api/tickets/${ticketId}/regenerate`, {
          method: "POST",
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Error regenerating AI response");
        }

        const data = await response.json();
        aiMessage.content = data.content;
        aiMessage.loading = false;

        // Update quota after regenerating AI response
        await this.fetchQuota();
      } catch (error) {
        aiMessage.loading = false; // Reset loading state if an error occurs
        this.addError(`Regenerating AI response failed: ${error.message}`);
      }
    },
    async deleteTicket(ticketId) {
      try {
        const response = await fetch(`/api/tickets/${ticketId}`, {
          method: "DELETE",
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Error deleting ticket");
        }

        this.tickets = this.tickets.filter(
          (ticket) => ticket.ticket_id !== ticketId
        );
        this.selectedTicket = null;
        // Auto-select the first ticket if none is selected
        if (this.tickets.length > 0 && !this.selectedTicket) {
          this.selectTicket(this.tickets[0]);
        }
      } catch (error) {
        this.addError(`Deleting ticket failed: ${error.message}`);
      }
    },

    async checkUserSession() {
      try {
        const response = await fetch("/api/user");
        if (!response.ok) {
          this.user = null;
          return;
        }

        const data = await response.json();
        this.user = data;
        await this.fetchTickets();
        await this.fetchQuota();
      } catch (error) {
        this.addError(`Checking user session failed: ${error.message}`);
      }
    },

    async fetchQuota() {
      try {
        const response = await fetch("/api/quota");
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Error fetching quota");
        }

        const data = await response.json();
        this.quota.max_consultations = data.max_consultations;
        this.quota.remaining_consultations = data.remaining_consultations;
        this.quota.max_ai_replies = data.max_ai_replies;
        this.quota.remaining_ai_replies = data.remaining_ai_replies;
        this.quota.window_hours = data.window_hours;
      } catch (error) {
        this.addError(`Fetching quota failed: ${error.message}`);
      }
    },
    selectTicket(ticket) {
      this.selectedTicket = ticket;
    },
    logout() {
      this.user = null;
      this.tickets = [];
      this.selectedTicket = null;
    },
    openModal() {
      this.isModalActive = true;
    },
    closeModal() {
      this.isModalActive = false;
    },
    openDisclaimerModal() {
      this.isDisclaimerModalActive = true;
    },
    closeDisclaimerModal() {
      this.isDisclaimerModalActive = false;
    },
    addError(errorMessage) {
      this.errors.push(errorMessage); // Add error to the global error array
    },
    removeError(index) {
      this.errors.splice(index, 1); // Remove error when dismissed
    },
    setCurrentPage(page_name) {
      this.currentPage = page_name;
    },
    updatePanelWidth(width) {
      // Update the panel width, dynamically affecting the margin of the main content
      this.panelWidth = width;
    },
    updateUsername(newUsername) {
      this.user.username = newUsername;
    },
  },
  mounted() {
    this.checkUserSession();
    this.fetchQuota();
    setInterval(this.fetchQuota, 10000);
    this.checkHashAndRedirect();

    // Listen to hash changes in the URL and redirect accordingly
    window.addEventListener("hashchange", this.checkHashAndRedirect);
  },
};
</script>

