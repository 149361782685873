<template>
  <div
    v-if="!consentGiven"
    class="cookie-consent-banner"
    style="position: fixed; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.8); color: white; padding: 15px; text-align: center; z-index: 9999;"
  >
    <p>
      We use cookies for the operation of this website. By continuing to use this site, you agree to our
      <a href="https://www.withsecure.com/en/about-us/legal/privacy#cookies" target="_blank" style="color: #0e9b6a;">cookie policy</a>.
    </p>
    <button @click="acceptCookies" class="btn btn-outline-light btn-sm">Accept</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      consentGiven: false,
    };
  },
  methods: {
    acceptCookies() {
      // Set cookie consent in localStorage or cookies
      localStorage.setItem("cookie_consent", "true");
      this.consentGiven = true;
    },
    checkCookieConsent() {
      // Check if the user already accepted cookies
      this.consentGiven = localStorage.getItem("cookie_consent") === "true";
    },
  },
  mounted() {
    this.checkCookieConsent();
  },
};
</script>
