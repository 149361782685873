<template>
  <div class="leaderboard-container container mt-4">
    <h1>Leaderboard</h1>

    <!-- Leaderboard Table -->
    <table class="table table-striped table-dark">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Username</th>
          <th scope="col">Challenges Solved</th>
          <th scope="col">Last Submission</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in leaderboard" :key="entry.username">
          <th scope="row">{{ index + 1 + (page - 1) * pageSize }}</th>
          <td>{{ entry.username }}</td>
          <td>{{ entry.correct_submissions }}</td>
          <td>{{ new Date(entry.last_submission).toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination Controls -->
    <nav aria-label="Leaderboard pagination">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: page === 1 }">
          <button class="btn btn-dark" @click="previousPage" :disabled="page === 1">Previous</button>
        </li>
        <li class="page-item" :class="{ disabled: page === totalPages }">
          <button class="btn btn-dark" @click="nextPage" :disabled="page === totalPages">Next</button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leaderboard: [],   // Stores leaderboard entries
      page: 1,           // Current page
      pageSize: 10,      // Number of entries per page
      totalEntries: 0,   // Total number of leaderboard entries
      pollingInterval: null,  // Variable to store the polling interval
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.pageSize);  // Calculate the total number of pages
    },
  },
  methods: {
    async fetchLeaderboard() {
      try {
        const response = await fetch(`/api/leaderboard?page=${this.page}&page_size=${this.pageSize}`);
        const data = await response.json();
        this.leaderboard = data.leaderboard;
        this.totalEntries = data.total_entries;
      } catch (error) {
        console.error("Error fetching leaderboard:", error);
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.fetchLeaderboard();
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page--;
        this.fetchLeaderboard();
      }
    },
    startPolling() {
      this.pollingInterval = setInterval(() => {
        this.fetchLeaderboard();
      }, 5000);  // Poll every 5 seconds
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
      }
    }
  },
  mounted() {
    this.fetchLeaderboard();  // Fetch leaderboard when the component is mounted
    this.startPolling();      // Start polling the leaderboard every 5 seconds
  },
  beforeUnmount() {
    this.stopPolling();       // Stop polling when the component is destroyed
  },
};
</script>

<style scoped>
.leaderboard-container h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.table {
  background-color: #343a40;
}

.pagination {
  margin-top: 20px;
}
</style>
