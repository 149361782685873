<template>
  <div class="card">
    <div class="card-header justify-content-between text-center">
      <h5>Your Consultations</h5>

      <div v-if="quota.remaining_tickets <= 0" data-bs-toggle="tooltip" title="Your quota for tickets has run out, delete some older tickets.">
        <button class="btn btn-outline-light btn-sm" disabled>
         <i class="fas fa-plus"></i> Request Advice</button>     
      </div>
      <div v-else>
        <button class="btn btn-outline-light btn-sm" @click="openModal" >
      <i class="fas fa-plus"></i> Request Advice</button>     
      </div>      
    </div>
    <ul class="list-group list-group-flush">
      <li
        v-for="ticket in tickets"
        :key="ticket.ticket_id"
        @click="$emit('select-ticket', ticket)"
        class="list-group-item list-group-item-action"
        :class="{'active': selectedTicket && selectedTicket.ticket_id === ticket.ticket_id}"
      >
        <strong>{{ ticket.title }}</strong>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['tickets', 'selectedTicket', 'quota'],
  methods: {
    openModal() {
      this.$emit('open-modal');
    }
  }
}
</script>

<style scoped>
.active {
  background-color: #5dc99f;
  color: white;
}

.list-group-item-action{
    cursor:pointer;
}

.list-group-item.active {
    border-color: green;
}
</style>
