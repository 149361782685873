<template>
  <div
    :class="['side-panel', { collapsed }]"
    :style="{ width: collapsed ? '50px' : sidePanelWidth + 'px' }"
  >
    <!-- Resizer Handle -->
    <div v-if="!collapsed" id="resizer" @mousedown="initResize"></div>

    <!-- Collapse Button at the top-right of the expanded panel -->
    <div v-if="!collapsed" class="collapse-button">
      <button class="btn btn-light btn-sm" @click="togglePanel">
        <i class="fas fa-arrow-left"></i>
      </button>
    </div>

    <!-- Always visible part when collapsed (vertical text and expand button) -->
    <div v-if="collapsed" class="toggle-button">
      <button class="btn btn-light btn-sm expand-btn" @click="togglePanel">
        <i class="fas fa-arrow-right"></i>
      </button>
      <span class="vertical-text">INSTRUCTIONS</span>
    </div>

    <!-- Expanded panel content -->
    <div v-if="!collapsed" class="panel-content">
      <h4>Instructions</h4>
      <p>This challenge is to experiment with multi-chain prompt injection.</p>

      <!-- Flag Submission Section -->
      <div class="flag-submission mb-4">
        <h5>Submit a Flag</h5>
        <p>
          All the flags have this format
          <span class="badge bg-success">w_flag_*******</span>.
        </p>
        <div class="input-group input-group-sm">
          <input
            v-model="submittedFlag"
            type="text"
            class="form-control form-control-sm"
            placeholder="Enter your flag"
            :disabled="!user"
          />
          <button
            class="btn btn-success btn-sm"
            @click="submitFlag"
            :disabled="!user || !submittedFlag || loading"
          >
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>

        <!-- Display success message if the flag submission is successful -->
        <div
          v-if="successMessage"
          class="alert alert-success alert-dismissible fade show mt-2"
          role="alert"
        >
          {{ successMessage }}
          <button
            type="button"
            class="btn-close"
            @click="dismissSuccess"
            aria-label="Close"
          ></button>
        </div>
        <div
          v-if="errorMessage"
          class="alert alert-danger mt-2 p-1"
          role="alert"
        >
          {{ errorMessage }}
          <button
            type="button"
            class="close"
            @click="dismissError"
            aria-label="Close"
            style="float: right; background: none; border: none"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!-- Loading indicator -->
        <div v-if="loading" class="loading-indicator mt-2">
          Submitting flag, please wait...
        </div>
      </div>
      <h5>Your quota</h5>
      <p v-if="!user">You need to login to activate your quota.</p>
      <ul v-else>
        <li>
          You have
          <span class="badge bg-danger">{{ quota.remaining_ai_replies }}</span>
          AI replies left out of a total of {{ quota.max_ai_replies }} per every
          {{ quota.window_hours }} hour(s).
        </li>
        <li>
          You can have a maximum of
          <span class="badge bg-danger">{{ quota.max_consultations }}</span> consultantions
          open, if you reach this limit just delete old ones.
        </li>
      </ul>

      <!-- Vue-based Accordion for flags -->
      <div class="accordion mt-4">
        <div class="accordion-item">
          <h2 class="accordion-header" @click="toggleAccordion(1)">
            <button
              class="accordion-button"
              :class="{ collapsed: activeAccordion !== 1 }"
            >
              Get started
            </button>
          </h2>
          <div v-if="activeAccordion === 1" class="accordion-body">
            <p>
              Create a new consultation request and you'll receive an AI Doctor
              response.
            </p>
            <strong>Hint:</strong>
            <p>
              Every time you get an AI response, you can see the debug trace,
              showing the inputs/outputs of all chains. This should help you
              figure out what's going on. You can get the entire source code
              that implements the chains from
              <a href="/llm_chains.py" target="_blank" download="llm_chains.py"
                >here</a
              >.
            </p>
          </div>
        </div>

        <!-- Flag 1 -->
        <div class="accordion-item">
          <h2 class="accordion-header" @click="toggleAccordion(2)">
            <button
              class="accordion-button"
              :class="{ collapsed: activeAccordion !== 2 }"
            >
              Flag 1
            </button>
          </h2>
          <div v-if="activeAccordion === 2" class="accordion-body">
            Obtain the <strong>unique ID</strong> of the
            AdvisorSimpleReactAgent. The debug trace will show you where this is
            (redacted).
          </div>
        </div>

        <!-- Flag 2 -->
        <div class="accordion-item">
          <h2 class="accordion-header" @click="toggleAccordion(3)">
            <button
              class="accordion-button"
              :class="{ collapsed: activeAccordion !== 3 }"
            >
              Flag 2
            </button>
          </h2>
          <div v-if="activeAccordion === 3" class="accordion-body">
            Obtain the <strong>insurance_code</strong> associated with your
            patient record.
          </div>
        </div>

        <!-- Flag 3 -->
        <div class="accordion-item">
          <h2 class="accordion-header" @click="toggleAccordion(4)">
            <button
              class="accordion-button"
              :class="{ collapsed: activeAccordion !== 4 }"
            >
              Flag 3
            </button>
          </h2>
          <div v-if="activeAccordion === 4" class="accordion-body">
            Obtain the <strong>insurance_code</strong> associated with patient
            ID <strong>35</strong>.
          </div>
        </div>

        <!-- Flag 4 -->
        <div class="accordion-item">
          <h2 class="accordion-header" @click="toggleAccordion(5)">
            <button
              class="accordion-button"
              :class="{ collapsed: activeAccordion !== 5 }"
            >
              Flag 4 (Bonus)
            </button>
          </h2>
          <div v-if="activeAccordion === 5" class="accordion-body">
            If you look at the debug trace, you'll see that the SymptomAdvisor
            agent has access to a secret tool that has been redacted from the
            debug trace. Enumerate that tool and casue the LLM to call it to
            reveal the final flag.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  props: ["quota", "user"],
  data() {
    return {
      collapsed: false,
      activeAccordion: 1,
      sidePanelWidth: 400,
      isResizing: false,
      lastDownX: 0,
      submittedFlag: "",
      loading: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    togglePanel() {
      this.collapsed = !this.collapsed;
      Cookies.set("sidePanelCollapsed", this.collapsed);

      // Restore the last saved width when expanding
      if (!this.collapsed) {
        const savedWidth = Cookies.get("sidePanelWidth");
        if (savedWidth) {
          this.sidePanelWidth = parseInt(savedWidth);
        }
      }
      this.$emit(
        "update-panel-width",
        this.collapsed ? 50 : this.sidePanelWidth
      );
    },
    toggleAccordion(section) {
      this.activeAccordion = this.activeAccordion === section ? null : section; // Toggle accordion
    },
    initResize(e) {
      this.isResizing = true;
      this.lastDownX = e.clientX;

      // Attach event listeners to handle resizing
      document.addEventListener("mousemove", this.resizePanel);
      document.addEventListener("mouseup", this.stopResize);
    },
    resizePanel(e) {
      if (!this.isResizing || this.collapsed) return; // Don't resize if the panel is collapsed

      const delta = e.clientX - this.lastDownX;
      const newWidth = this.sidePanelWidth + delta;

      // Limit the resizing to reasonable min and max width
      if (newWidth >= 150 && newWidth <= 600) {
        this.sidePanelWidth = newWidth;
        this.lastDownX = e.clientX;

        // Save the new width to the cookie
        Cookies.set("sidePanelWidth", this.sidePanelWidth);

        // Emit the updated width to the parent (App.vue)
        this.$emit("update-panel-width", this.sidePanelWidth);
      }
    },
    stopResize() {
      this.isResizing = false;

      // Remove event listeners when the mouse is released
      document.removeEventListener("mousemove", this.resizePanel);
      document.removeEventListener("mouseup", this.stopResize);
    },
    async submitFlag() {
      this.loading = true;
      this.successMessage = "";
      this.errorMessage = "";

      try {
        const formData = new URLSearchParams();
        formData.append("flag", this.submittedFlag);

        const response = await fetch("/api/submit-flag", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        });

        if (!response.ok) {
          const errorData = await response.json();
          this.errorMessage = errorData.detail || "Error submitting flag.";
          this.loading = false;
          return;
        }

        // On successful submission, show the success message
        this.successMessage = "Flag submitted successfully!";
        this.submittedFlag = ""; // Clear the input field
        this.loading = false;
      } catch (error) {
        this.errorMessage = "An unexpected error occurred.";
        console.error("Error submitting flag:", error);
        this.loading = false;
      }
    },
    dismissError() {
      this.errorMessage = ""; // Clear the success message when dismissed
    },
    dismissSuccess() {
      this.successMessage = ""; // Clear the success message when dismissed
    },
  },
  mounted() {
    // On component mount, check if the collapse state exists in the cookie and set the panel state accordingly
    const savedState = Cookies.get("sidePanelCollapsed");
    if (savedState !== undefined) {
      this.collapsed = savedState === "true"; // Cookies are stored as strings
    }

    // Check if the side panel width exists in the cookie and set the width accordingly
    if (!this.collapsed) {
      const savedWidth = Cookies.get("sidePanelWidth");
      if (savedWidth) {
        this.sidePanelWidth = parseInt(savedWidth);
      }
    }

    // Emit the initial width to the parent
    this.$emit("update-panel-width", this.collapsed ? 50 : this.sidePanelWidth);
  },
};
</script>

<style scoped>
.side-panel {
  position: fixed;
  top: 56px; /* Adjust according to the height of the navbar */
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 500;
  overflow: hidden;
  font-size: 0.9em;
}

.side-panel h4 {
  color: #5dc99f;
}

.side-panel.collapsed {
  transform: translateX(
    0px
  ); /* Collapse most of the panel, but leave part visible */
  width: 50px !important; /* Ensure width is 50px when collapsed */
}

.toggle-button {
  height: 100%;
  width: 50px;
  background-color: #343a40;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  flex-direction: column;
  padding: 10px 0;
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-top: 0px;
}

.panel-content {
  padding: 20px;
}

.panel-content h4 {
  margin-bottom: 10px;
}

a {
  color: #5dc99f;
}

.accordion-button {
  background-color: #5dc99f;
  color: white;
  padding: 12px;
}

.accordion-button:not(.collapsed),
.accordion-button:hover {
  background-color: #0fb172;
  color: white;
}

.accordion-body {
  background-color: #343a40;
  color: white;
}

/* Button to collapse the panel */
.collapse-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.collapse-button button,
.expand-btn {
  background-color: #f8f9fa;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expand-btn {
  margin-bottom: 0px;
}

/* Resizer Handle */
#resizer {
  width: 5px;
  background-color: black;
  cursor: ew-resize;
  position: absolute;
  right: 0;
  height: 100%;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

h5 {
  color: #5dc99f;
}

.flag-submission {
  margin-bottom: 20px;
}

.flag-submission .input-group {
  display: flex;
}

.flag-submission input {
  flex-grow: 1;
}

.flag-submission button {
  margin-left: 10px;
}
</style>
