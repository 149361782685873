<template>
  <div class="error-container" v-if="errors.length">
    <div v-for="(error, index) in errors" :key="index" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ error }}
      <button type="button" class="btn-close" @click="dismissError(index)" aria-label="Close"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  methods: {
    dismissError(index) {
      this.$emit('dismiss-error', index); // Emit the error index to remove it
    }
  }
}
</script>

<style scoped>
.error-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  width: 300px;
}
</style>
