<template>
  <div class="main-content container mt-4 genai-research">
    <h1>Our GenAI Research</h1>
    <p>
      We publish our research and thoughts on GenAI security on
      <a href="https://labs.withsecure.com/" target="_blank">WithSecure Labs</a
      >. Here are some articles that might be of interest:
    </p>
    <ul>
      <li>
        <a
          href="https://labs.withsecure.com/publications/browser-agents-llm-prompt-injection"
          target="_blank"
          >Should you let ChatGPT control your browser? (Prompt Injection in
          Browser Agents)</a
        >
      </li>
      <li>
        <a
          href="https://labs.withsecure.com/publications/gemini-prompt-injection"
          target="_blank"
          >When your AI Assistant has an evil twin (Google Gemini Prompt
          Injection)</a
        >
      </li>
      <li>
        <a
          href="https://labs.withsecure.com/publications/llm-agent-prompt-injection"
          target="_blank"
          >Synthetic Recollections (Prompt Injection in ReAct Agents)</a
        >
      </li>
      <li>
        <a
          href="https://labs.withsecure.com/publications/detecting-prompt-injection-bert-based-classifier"
          target="_blank"
          >Domain-Specific Prompt Injection Detection with BERT classifier</a
        >
      </li>
      <li>
        <a
          href="https://consulting.withsecure.com/webinars/building-secure-llm-apps-into-your-business/"
          target="_blank"
          >Jailbreak/Prompt Injection Security Canvas</a
        >
      </li>
    </ul>

    <h2>Open-Source Vulnerable LLM Apps</h2>
    <p>
      For hands-on practice with prompt injection and LLM vulnerabilities,
      explore these open-source vulnerable apps:
    </p>
    <ul>
      <li>
        <a
          href="https://github.com/WithSecureLabs/damn-vulnerable-llm-agent"
          target="_blank"
          >Damn Vulnerable LLM Agent</a
        >
      </li>
      <li>
        <a
          href="https://github.com/WithSecureLabs/llm-vulnerable-recruitment-app"
          target="_blank"
          >LLM Vulnerable Recruitment App</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "GenAIResearch",
};
</script>

<style scoped>


.genai-research h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.genai-research li {
  margin-bottom: 0.8rem;
}

.genai-research a {
  color: white;
  text-decoration: none;
  padding: 4px;
}

.genai-research a:hover {
  text-decoration: underline;
  color: black;

}
</style>
