<template>
  <div class="card" v-if="selectedTicket">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5>{{ selectedTicket.title }}</h5>
      <button class="btn btn-danger btn-sm" @click="openDeleteModal">
        <i class="fas fa-trash"></i> Delete Ticket
      </button>
    </div>
    <div class="card-body">
      <!-- Iterate over the messages in the selected ticket -->
      <div
        v-for="(message, index) in selectedTicket.messages"
        :key="index"
        class="mb-3"
      >
        <div class="card" :class="{ 'bg-light': message.is_ai_response }">
          <div class="card-body">
            <p>
              <strong v-if="message.is_ai_response">AI Response:</strong>
              <strong v-else>User Message:</strong>
              {{ message.content || "AI response being generated..." }}
              <!-- Show placeholder if AI response is missing -->
            </p>

            <!-- Regenerate button and spinner logic for AI response -->
            <div v-if="message.is_ai_response">
              <div v-if="!message.loading">
                <div
                  v-if="quota.remaining_ai_replies <= 0"
                  data-bs-toggle="tooltip"
                  title="Your quota for AI Responses has run out, try again later."
                >
                  <button class="btn btn-success btn-sm" disabled>
                    <i class="fas fa-sync-alt"></i> Regenerate AI Response
                  </button>
                </div>
                <div v-else>
                  <button
                    class="btn btn-success btn-sm"
                    @click="handleRegenerateAiResponse(message.id)"
                  >
                    <i class="fas fa-sync-alt"></i> Regenerate AI Response
                  </button>
                  <!-- Button to retrieve AI Debug Trace -->
                  <button
                    class="btn btn-info btn-sm ms-2"
                    @click="getAiDebugTrace"
                  >
                    <i class="fas fa-list"></i> View AI Debug Trace
                  </button>
                </div>
              </div>
              <div v-else class="text-center">
                <i class="fas fa-spinner fa-spin"></i> Generating answer...
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Placeholder for AI response if missing -->
      <div v-if="!aiResponseExists" class="card bg-light">
        <div class="card-body text-center">
          <i class="fas fa-spinner fa-spin"></i> AI response is being
          generated...
        </div>
      </div>
    </div>

    <div class="text-danger m-3">
      *Disclaimer: This application was built for a CTF and is not intended for
      real medical advice. Please refer to the full
      <a href="#" @click="openDisclaimerModal">disclaimer</a>.
    </div>

    <!-- Delete Confirmation Modal -->
    <div
      class="modal fade"
      :class="{ 'show d-block': isDeleteModalActive }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Deletion</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeDeleteModal"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              Are you sure you want to delete the ticket
              <strong>{{ selectedTicket.title }}</strong> and all associated
              messages?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeDeleteModal"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="deleteTicket">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- AI Debug Trace Modal -->
    <div
      class="modal fade"
      :class="{ 'show d-block': isDebugTraceModalActive }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">AI Debug Trace</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeDebugTraceModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="alert alert-warning">
              Note that all flag values are redacted from the debug trace
            </div>
            <pre class="debug-trace">{{ aiDebugTrace }}</pre>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeDebugTraceModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedTicket", "quota"],
  data() {
    return {
      isDeleteModalActive: false,
      isDebugTraceModalActive: false,
      aiDebugTrace: "", // Store the debug trace here
    };
  },
  computed: {
    aiResponseExists() {
      // Check if the ticket has an AI response
      return this.selectedTicket.messages.some((msg) => msg.is_ai_response);
    },
  },
  methods: {
    openDeleteModal() {
      this.isDeleteModalActive = true;
    },
    closeDeleteModal() {
      this.isDeleteModalActive = false;
    },
    handleRegenerateAiResponse(messageId) {
      this.$emit(
        "regenerate-ai-response",
        this.selectedTicket.ticket_id,
        messageId
      );
    },
    async deleteTicket() {
      await this.$emit("delete-ticket", this.selectedTicket.ticket_id);
      this.closeDeleteModal();
    },
    async getAiDebugTrace() {
      try {
        const response = await fetch(
          `/api/tickets/${this.selectedTicket.ticket_id}/ai-debug-trace`
        );
        const data = await response.json();
        this.aiDebugTrace = data.ai_debug_trace;
        this.openDebugTraceModal();
      } catch (error) {
        console.error("Failed to retrieve AI Debug Trace:", error);
      }
    },
    openDebugTraceModal() {
      this.isDebugTraceModalActive = true;
    },
    closeDebugTraceModal() {
      this.isDebugTraceModalActive = false;
    },
    openDisclaimerModal() {
      this.$emit("open-disclaimer-modal");
    },
  },
};
</script>

<style scoped>
/* Optional styling for the modals and buttons */
.btn-info {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.modal-content {
  background-color: #fff;
}

.modal-title {
  font-weight: bold;
}

.modal-body {
  white-space: pre-wrap;
}

.card {
  margin-bottom: 1rem;
}

.debug-trace {
  background-color: #e8e8e8;
  max-height: 550px;
  padding: 10px;
  white-space: pre-wrap;
}
</style>
